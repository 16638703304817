import { Badge, Box, Button, ButtonGroup, Flex, HStack, Stack } from '@chakra-ui/react'
import Card from 'components/design-system/card'
import { Form, Input, LevelSelect, Select, SubjectSelect, TagsInput } from 'components/design-system/forms'
import { SchoolAutocomplete } from 'components/design-system/school/school-autocomplete'
import { Formik } from 'formik'
import { isEmpty, omitBy, values } from 'lodash'
import { SEMESTER_VALUES } from 'utils/constants'
import { getPaginationFilters } from 'utils/Pagination'

interface MetaTag {
  value: string
}

interface School {
  id: string
  name: string
  address?: string
}

export interface FilterValues {
  title: string
  name?: string
  levelId: string
  subjectId: string
  school?: School
  schoolId?: string
  metaTags: MetaTag[]
}

export function filtersForRoute(route, query, filters = null, page = null) {
  const filterStringified = JSON.stringify(filters || getPaginationFilters(route) || {})
  const pageQuery = filters && !page ? 1 : page
  const filtersApplied = filterStringified != '{}' ? { filters: filterStringified } : null

  return filtersApplied
    ? {
        pathname: route,
        query: { ...query, ...filtersApplied, page: pageQuery }
      }
    : {
        pathname: route
      }
}

export function fromQueryToFilters(query) {
  const filters = JSON.parse(query.filters.toString())

  return {
    ...filters,
    metaTags:
      filters?.metaTags && filters?.metaTags.length > 0
        ? {
            column: 'ID',
            operator: 'IN',
            value: filters.metaTags.map((x) => x.value)
          }
        : undefined
  }
}

export default function TableFilters({
  withName = false,
  withTitle = false,
  withLevel = false,
  withSubject = false,
  withSemester = false,
  withTags = false,
  withSchools = false,
  onFiltersChange,
  forLessonsPath = false,
  initialValues = {} as FilterValues
}) {
  const onSearch = async (filters) => {
    onFiltersChange({ ...omitBy(filters, isEmpty), metaTags: filters.metaTags })
  }

  const onCleanSearch = async (formik) => {
    formik.resetForm()

    onFiltersChange({})
  }

  const countFilters = () => {
    return values(initialValues).filter((x) => x != '' && x != []).length
  }

  const semesterItems = SEMESTER_VALUES.map((on) => {
    return { name: on, value: on }
  })

  return (
    <Card mb={4}>
      <h2>
        <Box flex="1" textAlign="left" style={{ fontWeight: 'bolder' }}>
          Filters
          {countFilters() > 0 && (
            <Box as={'span'} paddingLeft={2}>
              <Badge colorScheme={'red'}>{countFilters()}</Badge>
            </Box>
          )}
        </Box>
      </h2>
      <Formik initialValues={initialValues} onSubmit={onSearch} validateOnChange={false} validateOnBlur={false}>
        {(formik) => (
          <>
            <Form isLoading={false}>
              <Stack mx="4">
                <Box>
                  {withName && <Input label="Name" name="name" placeholder=" Name" />}

                  {withTitle && <Input label="Title" name="title" placeholder=" Title" />}

                  <HStack justifyContent={'space-between'}>
                    {withLevel && <LevelSelect label="Level" name="levelId" placeholder="Choose Level" />}

                    {withSubject && (
                      <SubjectSelect
                        label="Subject"
                        name="subjectId"
                        placeholder="Choose Subject"
                        forPath={forLessonsPath}
                      />
                    )}

                    {withSemester && (
                      <Select label="Semester" name="semester" placeholder="Choose Semester" items={semesterItems} />
                    )}
                  </HStack>

                  {withSchools && (
                    <SchoolAutocomplete
                      label="School"
                      name="schoolId"
                      placeholder="Type school name"
                      initialSchoolId={initialValues?.schoolId}
                      isRequired={false}
                      helper=""
                    />
                  )}

                  {withTags && <TagsInput label="Tags" name="metaTags" placeholder="Start typing a tag" />}
                </Box>
              </Stack>
            </Form>

            <Flex alignItems={'flex-end'} flexDirection="column" mt={4}>
              <ButtonGroup spacing={2}>
                <Button onClick={() => onCleanSearch(formik)}>Clean</Button>
                <Button onClick={() => formik.handleSubmit()} colorScheme="purple">
                  Filter
                </Button>
              </ButtonGroup>
            </Flex>
          </>
        )}
      </Formik>
    </Card>
  )
}
