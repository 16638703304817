import { Box, useColorModeValue } from '@chakra-ui/react'

export default function Card({ children, ...rest }) {
  return (
    <Box
      w={'full'}
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'2xl'}
      borderRadius={'2xl'}
      py={8}
      px={16}
      {...rest}
    >
      {children}
    </Box>
  )
}
