import { Icon } from '@chakra-ui/react'
import React from 'react'
import { FiEdit3 } from 'react-icons/fi'

type IconProps = {
  size: number
}

const DeleteIcon = ({ size, ...props }: IconProps) => {
  return <Icon {...props} color="black" style={{ fontSize: size }} as={FiEdit3} />
}

export default DeleteIcon
