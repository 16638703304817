import {
  Box,
  Flex,
  Icon,
  IconButton,
  Skeleton,
  Table as CTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import { last, range } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { usePagination, useTable } from 'react-table'
import { getPaginationIndex } from 'utils/Pagination'

export type Action = (arg: any) => void

export interface Column {
  header: string
  accessor: string
  isNumeric?: boolean
}

export interface Styles {
  actions: any
}

export interface Table {
  columns: Column[]
  data: any[]
  actions: ActionConfig[]
  styles?: Styles
  size?: string
}

export interface ActionConfig {
  text: string
  call: Action
  Component: (props) => JSX.Element
  disabled?: (arg: any) => boolean
}

export interface TableProps {
  table: Table
  isLoading: boolean
  pageCount: number
  pageSize: number
  initialPage?: number
  fetchData: (pageIndex: number, pageSize: number) => void
  isPaginated: boolean
  filters: any
  path: string
}

export enum TableAction {
  Add,
  Remove
}

export function getPageAfterAction(action: TableAction, page: number, pageSize: number, pageCount: number) {
  switch (action) {
    case TableAction.Add:
      return pageCount == pageSize ? page + 1 : page
    case TableAction.Remove:
      return pageCount == 1 ? page - 1 : page
    default:
      return page
  }
}

function PaginationItem({ gotoPage, page, isActive = false }) {
  const router = useRouter()

  return (
    <Flex
      fontWeight="bold"
      as="span"
      borderRadius={60}
      justifyContent="center"
      alignItems="center"
      h="35px"
      w="35px"
      bgColor={isActive ? 'gray.300' : 'gray.100'}
      mx="6px"
      _hover={{
        backgroundColor: 'gray.200'
      }}
      onClick={() => {
        router.push(
          {
            pathname: `/${router.pathname}`,
            query: { ...router.query, page: page.toString() }
          },
          undefined,
          { shallow: true }
        )
        gotoPage(page)
      }}
    >
      {page}
    </Flex>
  )
}

// eslint-disable-next-line no-redeclare
export default function Table({
  table,
  isLoading,
  initialPage,
  fetchData,
  pageSize: controlledPageSize,
  pageCount: controlledPageCount,
  isPaginated = true,
  path
}: TableProps) {
  const router = useRouter()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns: table.columns,
      data: table.data,
      manualPagination: true,
      autoResetPage: false,
      pageCount: controlledPageCount + 1,
      initialState: {
        pageIndex: router.query.page ? parseInt(router.query.page.toString()) : getPaginationIndex(path),
        pageSize: controlledPageSize
      }
    },
    usePagination
  )
  const lastPage = pageCount - 1
  const pagesToDisplay = getPagesToDisplay().filter((x) => x != 1 && x != lastPage)

  useEffect(() => {
    fetchData && fetchData(pageIndex, pageSize)
  }, [pageIndex, pageSize])

  useEffect(() => {
    if (initialPage && initialPage != pageIndex) {
      gotoPage(initialPage)
    }
  }, [initialPage])

  function getPagesToDisplay() {
    const pivot = pageIndex
    const numberOfSurroundingElements = 2
    const initial = pivot - numberOfSurroundingElements
    const final = pivot + numberOfSurroundingElements

    if (lastPage < 2 * numberOfSurroundingElements + 1) {
      return range(1, lastPage)
    }

    if (initial < 1) {
      return range(2, 2 + 2 * numberOfSurroundingElements)
    }

    if (final > lastPage) {
      return range(lastPage - 2 * numberOfSurroundingElements, lastPage)
    }

    return range(initial, final + 1)
  }

  return (
    <Box w="100%" style={{ overflowX: 'auto' }}>
      <CTable {...getTableProps()} size={table.size || 'md'}>
        <Thead>
          {headerGroups.map((headerGroup, headerRowIndex) => (
            <Tr key={`header${headerRowIndex}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, headerColIndex) => (
                <Th
                  key={`headerCol${headerColIndex}`}
                  {...column.getHeaderProps()}
                  isNumeric={column.isNumeric}
                  style={column.headerStyle}
                >
                  {column.render('header')}
                </Th>
              ))}

              {table.actions && table.actions.length > 0 && <Th style={{ ...table.styles?.actions }}>Actions</Th>}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row)

            return (
              <Tr key={`row${rowIndex}`} {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <Td key={`cell${cellIndex}`} {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                    <Skeleton isLoaded={!isLoading}>{cell.render('Cell')}</Skeleton>
                  </Td>
                ))}

                {table.actions && table.actions.length > 0 && (
                  <Td style={table.styles?.actions}>
                    {table.actions.map((action, index) => (
                      <action.Component
                        disabled={isLoading || (!!action.disabled && action.disabled(row.original))}
                        key={`row-${action.text}-${index}`}
                        onClick={async () => {
                          await action.call(row.original)
                        }}
                      />
                    ))}
                  </Td>
                )}
              </Tr>
            )
          })}
        </Tbody>
      </CTable>

      {isPaginated && (
        <Flex justifyContent="space-between" m={4} alignItems="center">
          <Flex>
            <Tooltip label="Previous Page">
              <IconButton
                aria-label="Previous Page"
                onClick={() => previousPage()}
                isDisabled={!canPreviousPage || pageIndex <= 1}
                icon={<Icon m={2} color="trueGray.400" style={{ fontSize: 16 }} as={FaAngleLeft} name="angle-left" />}
              />
            </Tooltip>
          </Flex>

          <Flex alignItems="center">
            {lastPage > 1 && <PaginationItem page={1} gotoPage={gotoPage} isActive={pageIndex == 1} />}

            {pagesToDisplay[0] > 2 && '...'}

            {pagesToDisplay.map((page, pageIndex) => (
              <PaginationItem key={`page${pageIndex}`} page={page} gotoPage={gotoPage} isActive={page == pageIndex} />
            ))}

            {last(pagesToDisplay) < lastPage - 1 && '...'}

            <PaginationItem page={lastPage} gotoPage={gotoPage} isActive={lastPage == pageIndex} />
          </Flex>

          <Flex>
            <Tooltip label="Next Page">
              <IconButton
                aria-label="Next Page"
                onClick={() => nextPage()}
                isDisabled={!canNextPage}
                icon={<Icon m={2} color="trueGray.400" style={{ fontSize: 16 }} as={FaAngleRight} name="angle-right" />}
              />
            </Tooltip>
          </Flex>
        </Flex>
      )}
    </Box>
  )
}
