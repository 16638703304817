import { Difficulty, Semester } from 'graphql/generated'

export const TUTOR_NOTES_WIDTH = 425

export const FOOTER_HEIGHT = 75

export const SLIDE_NEXT_BUTTON_WIDTH = 85
export const SLIDE_PREV_BUTTON_WIDTH = 85

export const SEMESTER_VALUES = [Semester.First, Semester.Second]
export const SEMESTER_FIRST = Semester.First
export const SEMESTER_SECOND = Semester.Second

export const LOW_DIFFICULTY = Difficulty.Low
export const MEDIUM_DIFFICULTY = Difficulty.Medium
export const HIGH_DIFFICULTY = Difficulty.High
